@font-face {
  font-family: "nice";   /*Can be any text*/
  src: local("Nice-Regular"), url("./assets/fonts/cardo/Nice-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "nice-bold";   /*Can be any text*/
  src: local("Nice-Bold"), url("./assets/fonts/cardo/Nice-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "inter";   /*Can be any text*/
  src: local("Inter-Regular"), url("./assets/fonts/cardo/Inter-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "inter-light";   /*Can be any text*/
  src: local("Inter-Light"), url("./assets/fonts/cardo/Inter-Light.ttf") format("truetype");
}

@font-face {
  font-family: "inter-bold";   /*Can be any text*/
  src: local("Inter-Light"), url("./assets/fonts/cardo/Inter-Bold.ttf") format("truetype");
}

html, body {
  margin: 0;
  height: 100%;
  font-family: 'nice'
}

::-webkit-scrollbar {
  width: 0px;
}

